html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

.App {
    background-color: #282A36;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    height: 100vh;
    z-index: 0;
}

.footer {
    bottom: 0;
    width: 100%;
}

@media (max-width: 1100px) {
    .content {
        height: 100%;
        overflow-y: auto;
    }
}

@font-face {
    font-family: "Outfit";
    src: url("/src/assets/fonts/Outfit-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Olde English";
    src: url("/src/assets/fonts/OldeEnglish.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}