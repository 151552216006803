.portrait-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 50px;
  box-sizing: border-box;
  margin-top: 225px; /* Adjust this value to push content below the navbar */
  z-index: 1;
}