.bg-square-top {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: fit-content;
    height: fit-content;
    overflow: hidden;
    pointer-events: none;
}

.bg-square-bottom {
    display: flex;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;
    width: fit-content;
    height: fit-content;
    overflow: hidden;
    pointer-events: none;
}


@media (max-width: 1100px) {
    .bg-square-top {
        display: flex;
        top: 0;
        position: absolute;
        height: auto;
        z-index: 0;
    }

    .bg-square-bottom {
        display: flex;
        position: fixed;
        right: 0;
        bottom: 0;
        z-index: -1;
    }
}