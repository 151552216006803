/* --------------- Navbar --------------- */
.navbar {
    background-image: url(/src/assets/navbar/NavbarContainer.svg);
    position: fixed;
    height: 63px;
    display: flex;
    width: 100%;
    padding-left: 50px;
    align-items: center;
    box-sizing: border-box;
    z-index: 1;
    overflow: hidden;
}

@media (max-width: 1100px) {
    .navbar {
        width: 100%;
        white-space: nowrap;
        padding-left: 50px;
    }
    .navbar ul {
        align-items: center;
        justify-content: flex-start;
        overflow-x: auto;
    }
    .navbar ul::-webkit-scrollbar {
        display: none;
    }
    .signin-container {
        display: none;
        position: relative;
    }
}

.navbar ul {
    list-style-type: none;
    display: flex;
    margin-left: auto;
    gap: 20px;
    justify-content: space-between;
    padding-right: 50px;
}

.navbar-button {
    all: unset;
    background-image: url("/src/assets/navbar/navButton.svg");
    background-size: contain;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 75px;
    height: 31px;
    z-index: 1;
    cursor: pointer;
    border: none;
    outline: none;
}

.navbar-link {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 75px;
    height: 31px;
    text-decoration: none;
}

.navbar-text {
    z-index: 2;
    color: black;
    font-size: 14px;
    font-weight: bold;
    position: relative;
    font-family: "Outfit", sans-serif;
}

/* --------------- Logo --------------- */

.logo {
    background-image: url(/src/assets/navbar/logo.svg);
    background-size: contain;
    background-repeat: no-repeat;
    width: 55px;
    height: 55px;
    flex-shrink: 0;
}

/* --------------- Search Button --------------- */

.search-icon {
    width: 20px;
    height: 20px;
    object-fit: contain;
}

/* --------------- Sign In Button --------------- */

.signin-container {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
}

.signin-text {
    position: absolute;
    z-index: 2;
    color: black;
    font-size: 30px;
    font-family: "Olde English", sans-serif;
}

.signin-button {
    background-image: url("/src/assets/navbar/signInButton.svg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 80px;
    height: 63px;
    z-index: 1;
    border: none;
}