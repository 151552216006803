.footer-container {
    background-color: #44475A;
    align-items: center;
    justify-content: center;
    width: 100%;
    z-index: 0;
}

.footer-block-container {
    align-items: center;
    justify-content: center;
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    z-index: 0;
}

.footer-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 20px;
    z-index: 0;
    width: fit-content;
    height: fit-content;
    overflow: hidden;
}

.footer-content {
    display: flex;
    flex-direction: column; /* Stack children vertically */
    align-items: initial; /* Center-align the content horizontally */
    position: absolute;
    gap: 10px; /* Add spacing between items */
    color: white;
    font-family: "Outfit", sans-serif;
    font-weight: bold;
    font-size: 14px;
    z-index: 0;
}

@media (max-width: 1100px) {
    .footer-block-container {
        flex-direction: column;
        gap: 1px;
        z-index: 0;
    }
}

.box-quote-container {
    display: flex;
    align-items: center;
    padding-top: 25px;
    z-index: 0;
}

.bbox {
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    bottom: 0;
    left: 0;
}

.box-text {
    position: absolute;
    color: white;
    font-family: "Outfit", sans-serif;
    font-weight: bold;
    font-size: 12px;
}